<template>
    <div>
        <b-row>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('tyyc_basic_field')">
                    <div class="label-as-input">{{ getObjectValue(formData, this.getLocaleField('tyyc_basic_field_name')) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('tyyc_basic_field_type')">
                    <div class="label-as-input">{{ getObjectValue(formData, this.getLocaleField('tyyc_basic_field_type_name')) }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('program_level')">
                    <div class="label-as-input">{{ getObjectValue(formData, 'program_level') }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('type')">
                    <div class="label-as-input">{{ formData.type ? formData.type == 1 ? $t('academic') : $t('vocational') : '-'  }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('tyyc_outcome')">
                    <div class="label-as-input">{{ getObjectValue(formData, 'outcome') }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('tyyc_outcome')+' (EN)'">
                    <div class="label-as-input">{{ getObjectValue(formData, 'outcome_en') }}</div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="6">
                <b-form-group :label="$t('rank')">
                    <div class="label-as-input">{{ getObjectValue(formData, 'rank') }}</div>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    // Services
    import TyycBasicFieldOutcomesService from "@/services/TyycBasicFieldOutcomesService"

    export default {
        components: {

        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formData: {},
                formLoading: false
            }
        },
        async created() {
            this.get(this.formId)
        },
        methods: {
            get(id) {
                TyycBasicFieldOutcomesService.get(id).then(response => {
                    this.formData = response.data.data
                }).catch(e => {
                    this.showErrors(e)
                })
            }
        }
    }
</script>
