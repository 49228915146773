<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('tyyc_basic_field_outcomes')"
                        :isNewButton="checkPermission('tyycbasicfieldoutcome_store')"
                        :isColumns="true"
                        @new-button-click="createFormShow()"
                        @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'">
                                    {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('tyyc_basic_field_outcomes')"
                              :isNewButton="checkPermission('tyycbasicfieldoutcome_store')"
                              :isColumns="true"
                              @new-button-click="createFormShow()"
                              @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'">
                                    {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="6">
                        <b-form-group :label="$t('tyyc_basic_field_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.tyyc_basic_field_type"
                                                 code="tyyc_basic_field_types" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6">
                        <b-form-group :label="$t('program_level')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.program_level"
                                                 code="program_levels" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>

            <CommonModal ref="formModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    <span v-if="formProcess=='show'">{{ $t('detail').toUpper() }}</span>
                    <span v-if="formProcess=='create'">{{ $t('new').toUpper() }}</span>
                    <span v-if="formProcess=='update'">{{ $t('update').toUpper() }}</span>
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show' && checkPermission('department_show')"
                    />
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create' && checkPermission('department_store')"
                    />
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update' && checkPermission('department_update')"
                    />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Components
import DatatableFilter from '@/components/datatable/DatatableFilter'
import Datatable from '@/components/datatable/Datatable'
import CommonModal from '@/components/elements/CommonModal'
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'

// Pages
import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm';
import ShowForm from './ShowForm';

// Services
import TyycBasicFieldOutcomesService from "@/services/TyycBasicFieldOutcomesService"

// Others
import qs from 'qs'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ParameterSelectbox,
        ShowForm,
        CreateForm,
        UpdateForm,
    },
    metaInfo() {
        return {
            title: this.$t('tyyc_basic_field_outcomes')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'tyycbasicfieldoutcome_show',
                                callback: (row) => {
                                    this.showForm(row.id);
                                }
                            },
                            {
                                text: this.$t('edit'),
                                permission: 'tyycbasicfieldoutcome_update',
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.updateFormShow(row.id)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                permission: 'tyycbasicfieldoutcome_delete',
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.formDelete(row.id)
                                }
                            }
                        ]
                    },
                    {
                        label: 'ID',
                        field: 'id',
                        hidden: true
                    },
                    {
                        label: this.$t('rank'),
                        field: 'rank',
                        hidden: false
                    },
                    {
                        label: this.$t('tyyc_basic_field'),
                        field: this.getLocaleField('tyyc_basic_field_name'),
                        hidden: false
                    },
                    {
                        label: this.$t('tyyc_basic_field_type'),
                        field: this.getLocaleField('tyyc_basic_field_type_name'),
                        hidden: false
                    },
                    {
                        label: this.$t('program_level'),
                        field: 'program_level',
                        hidden: false
                    },
                    {
                        label: this.$t('type'),
                        field: 'type',
                        hidden: false,
                        formatFn: (row) => {
                            return row ? row == 1 ? this.$t('academic') : this.$t('vocational') : ''
                        }
                    },
                    {
                        label: this.$t('tyyc_outcome'),
                        field: 'outcome',
                        hidden: false
                    },
                    {
                        label: this.$t('tyyc_outcome')+' (EN)',
                        field: 'outcome_en',
                        hidden: false
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: null,
            form: {}
        }
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {}
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            TyycBasicFieldOutcomesService.getAll(config).then(response => {
                this.datatable.rows = response.data.data
                this.datatable.total = response.data.pagination.total
            }).finally(() => {
                this.datatable.isLoading = false;
            });
        },

        // Clear
        formClear() {
            this.formId = null
            this.formProcess = null
        },

        // Show
        showForm(id) {
            this.formId = id
            this.formProcess = 'show'
            this.$refs.formModal.$refs.commonModal.show()
        },

        // Create
        createFormShow() {
            this.formId = null
            this.formProcess = 'create'
            this.$refs.formModal.$refs.commonModal.show()
        },
        createFormSuccess() {
            this.$refs.formModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },

        // Update
        updateFormShow(id) {
            this.formId = id
            this.formProcess = 'update'
            this.$refs.formModal.$refs.commonModal.show()
        },
        updateFormSuccess() {
            this.$refs.formModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },

        // Delete
        formDelete(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then((result) => {
                if (result.isConfirmed) {
                    TyycBasicFieldOutcomesService.del(id).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getRows();
                    }).catch(e => {
                        this.showErrors(e)
                    })
                }
            })
        }
    }
}
</script>

