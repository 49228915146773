<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="4" lg="6">
                    <ValidationProvider name="tyyc_basic_field" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('tyyc_basic_field')">
                            <parameter-selectbox v-model="formData.tyyc_basic_field"
                                                 code="tyyc_basic_fields"
                                                 :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="6">
                    <ValidationProvider name="tyyc_basic_field_type" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('tyyc_basic_field_type')">
                            <parameter-selectbox v-model="formData.tyyc_basic_field_type"
                                                 code="tyyc_basic_field_types"
                                                 :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="6">
                    <ValidationProvider name="program_level" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('program_level')">
                            <parameter-selectbox v-model="formData.program_level"
                                                 code="program_levels"
                                                 :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="6">
                    <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('type')">
                            <multi-selectbox v-model="formData.type"
                                             :options="typeOptions"
                                             :multiple="false"
                                             :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="6">
                    <ValidationProvider name="outcome" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('tyyc_outcome')">
                            <b-form-textarea v-model="formData.outcome"
                                             rows="4"
                                             :state="errors[0] ? false : null"/>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="6">
                    <ValidationProvider name="outcome_en" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('tyyc_outcome')+' (EN)'">
                            <b-form-textarea v-model="formData.outcome_en"
                                             rows="4"
                                             :state="errors[0] ? false : null"/>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="6">
                    <ValidationProvider name="rank" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('rank')">
                            <b-form-input type="number"
                                          v-model="formData.rank"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
    import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

    // Services
    import TyycBasicFieldOutcomesService from "@/services/TyycBasicFieldOutcomesService"

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            ParameterSelectbox,
            MultiSelectbox
        },
        data() {
            return {
                formData: {},
                formLoading: false,
                typeOptions: [
                    {value: 1, text: this.$t('academic')},
                    {value: 2, text: this.$t('vocational')}
                ],
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    TyycBasicFieldOutcomesService.store(this.formData).then(response => {
                        this.$emit('createFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    }).finally(() => {
                        this.formLoading = false
                    })
                }
            }
        }
    }
</script>

